import React, {Component} from "react";
import { Link } from "react-router-dom";

class Index extends Component{
    render(){
        return(
            <main>
                <h2>PERSONAL PROJECTS, COMICS, ETC.</h2>
                <blockquote className="twitter-tweet"><p lang="en" dir="ltr"><i>my dear friend misty succinctly described wordle yesterday as breaking the barrier of &quot;there only being three websites anymore&quot; that the modern web has consolidated into and, like, how rapidly we saw the result of that. Consolidate, consolidate.</i> <a href="https://t.co/6ASfzds6O8">https://t.co/6ASfzds6O8</a></p>&mdash; Aura🥨🥨🥨 (@MOOMANiBE) <a href="https://twitter.com/MOOMANiBE/status/1488277103346810881?ref_src=twsrc%5Etfw">January 31, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script> 
                <p>This site houses various development projects and other, more personal projects I've put together over the years, [mostly] independent of other platforms.</p>
                <h2>DIRECTORY</h2>
                <ul>
                    <li>I write <a href="https://ia-ia-ia.world">comics</a>.</li>
                    <li>I also run a biweekly Dungeons & Dragons campaign. You can read the most recent update <Link to="/dnd">here</Link>.</li>
                    <li><Link to="/bingo">Inoue Bingo</Link> is a bingo sheet generator for shows written by the infamous Toshiki Inoue.</li>
                    <li>Check out some <Link to="/links">other sites</Link> run by myself or friends.</li>
                </ul>
                <h2>A FEW ADDITIONAL WORDS</h2>
                <p>If you'd like to support me or what I do, you may <a href="https://ko-fi.com/goodjobrammy">buy me a coffee.</a></p>
            </main>
        );
    }
}

export default Index;